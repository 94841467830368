 <template>
  <v-container fluid>
    <h1>Component 5</h1>

    <v-alert type="success"
             width="fit-content"
             style="position: absolute;"> Old gui iframe test.</v-alert>
    <iframe id="iframe_oldgui"
            style="width: 100%; height: 80vh;"
            :src="GenerateURL()" />

  </v-container>
</template>
 
 
 <script>
export default {
  name: "Component5",
  props: {},
  components: {},

  data() {
    return {};
  },

  mounted() {
    setTimeout(() => this.doMagic(), 1000);
  },

  methods: {
    GenerateURL() {
      return (
        window.location.origin +
        "/ServiceProviderWebSite/Views/Job/ManageJob.aspx"
      );
    },

    doMagic() {
      document
        .getElementById("iframe_oldgui")
        .contentDocument.getElementById(
          "MainMenuContent_mainMenu_planningMenuHyperlink"
        )
        .click();
    },
  },

  computed: {},

  watch: {},
};
</script>
 
 
 <style lang='scss' scoped>
</style>